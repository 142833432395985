<div class="contentDiv" *ngIf="finishLoading">
  <div>若您的資料有誤且該欄位不可編輯時，請聯繫您的NN人員協助修改。</div>

  <div class="infoFieldDiv">
    <mat-label class="nnhcpLabel">醫事人員姓名*</mat-label>
    <mat-form-field appearance="outline">
      <input
        matInput
        required="{{ action === 'add' }}"
        [(ngModel)]="hcpName"
        [disabled]="hcpItemDataSource === 'IQVIA'"
      />
    </mat-form-field>

    <mat-label class="nnhcpLabel">手機號碼*</mat-label>
    <mat-form-field appearance="outline">
      <input
        placeholder="ex:0912345678"
        matInput
        required="{{ action === 'add' }}"
        [(ngModel)]="phone"
        [disabled]="hcpItemDataSource === 'IQVIA' && !!hcpItemOriginPhone"
      />
    </mat-form-field>

    <mat-label class="nnhcpLabel">Email</mat-label>
    <mat-form-field appearance="outline">
      <input matInput [(ngModel)]="email" [disabled]="hcpItemDataSource === 'IQVIA'" />
    </mat-form-field>

    <!-- 迴圈起始 -->
    <ng-container *ngFor="let medicalInstitutionFormData of medicalInstitutionFormList; let i = index">
      <mat-label class="nnhcpLabel" *ngIf="i === 0">醫療院所*</mat-label>
      <div class="infoFieldBoxDiv">
        <button
          mat-icon-button
          class="btnDeleteInfoFieldBoxDiv"
          (click)="_btnDelInfoField(i)"
          [disabled]="medicalInstitutionFormData.boundTerritoryDataSource === 'IQVIA'"
        >
          <img src="assets/images/hcp/cancel.svg" />
        </button>
        <mat-form-field appearance="outline">
          <input
            type="text"
            placeholder="請輸入院所名稱並於選單中選擇"
            matInput
            [matAutocomplete]="auto"
            [value]="medicalInstitutionFormData.medicalInstitutionName"
            #medicalInstitutionNameInput
            (input)="inputChange($event, i)"
            (blur)="onInputBlur($event, i)"
            [disabled]="medicalInstitutionFormData.boundTerritoryDataSource === 'IQVIA'"
            matAutocompletePosition="below"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            (optionSelected)="optionSelectedMedicalInstitution($event, i)"
          >
            <mat-option
              *ngFor="let medicalInstitution of autoCompleteMedicalInstitutionList"
              [value]="medicalInstitution"
            >
              {{ medicalInstitution.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="cityDiv">
          <mat-form-field appearance="outline">
            <mat-select
              [value]="medicalInstitutionFormData.selectedCounty"
              (valueChange)="countyChange($event, i)"
              [disabled]="medicalInstitutionFormData.boundTerritoryDataSource === 'IQVIA'"
            >
              <mat-option value="">請選擇縣市</mat-option>
              <mat-option *ngFor="let county of countyList || []" [value]="county"> {{ county.countyName }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-select
              [value]="medicalInstitutionFormData.selectedDistrict"
              (valueChange)="districtChange($event, i)"
              [disabled]="medicalInstitutionFormData.boundTerritoryDataSource === 'IQVIA'"
            >
              <mat-option value="">請選擇區域</mat-option>
              <mat-option
                *ngFor="let district of medicalInstitutionFormData.selectedCounty?.districtList || []"
                [value]="district"
              >
                {{ district.districtName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline">
          <mat-select
            [(value)]="medicalInstitutionFormData.selectedSubMedicalInstitution"
            [disabled]="
              !medicalInstitutionNameInput.value || medicalInstitutionFormData.boundTerritoryDataSource === 'IQVIA'
            "
          >
            <mat-option value="">請選擇科別</mat-option>
            <mat-option
              *ngFor="let subMedicalInstitution of medicalInstitutionFormData.subMedicalInstitutionList"
              [value]="subMedicalInstitution"
              >{{ subMedicalInstitution.department }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <!-- 迴圈結束 -->
    <ng-container *ngIf="!!hcpItemData || medicalInstitutionFormList.length === 0">
      <div class="action-add-free-text" *ngIf="medicalInstitutionFormList.length < 5">
        <button type="button" (click)="addMedicalInstitution()">＋新增醫療院所</button>
      </div>
    </ng-container>

    <hr />

    <p class="nnhcpLabel territoryOptionImgDiv">若查詢不到所屬院所，請點以下按鈕新增</p>
    <div class="action-add-free-text" *ngIf="!selectedAddMedicalInstitution">
      <button type="button" (click)="addFreeTextMedicalInstitution()">＋手動新增院所資料</button>
    </div>

    <!-- 手動新增院所資料 -->
    <ng-container *ngIf="selectedAddMedicalInstitution">
      <div class="infoFieldBoxDiv">
        <div class="btnDeleteInfoFieldBoxDiv" (click)="_btnDelInfoField('freeText')">
          <img src="assets/images/hcp/cancel.svg" />
        </div>
        <mat-form-field appearance="outline">
          <input
            type="text"
            placeholder="請自行輸入院所名稱"
            matInput
            [(ngModel)]="freeText.medicalInstitutionName"
            (input)="inputChange($event, 'freeText')"
            matAutocompletePosition="below"
          />
        </mat-form-field>
        <div class="cityDiv">
          <mat-form-field appearance="outline">
            <mat-select [value]="freeText.selectedCounty" (valueChange)="countyChange($event, 'freeText')">
              <mat-option value="">請選擇縣市</mat-option>
              <mat-option *ngFor="let county of countyList || []" [value]="county"> {{ county.countyName }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-select [value]="freeText.selectedDistrict" (valueChange)="districtChange($event, 'freeText')">
              <mat-option value="">請選擇區域</mat-option>
              <mat-option *ngFor="let district of freeText.selectedCounty?.districtList || []" [value]="district">
                {{ district.districtName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline">
          <mat-select [(value)]="freeText.selectedSubMedicalInstitution">
            <mat-option value="">請選擇科別</mat-option>
            <mat-option
              *ngFor="let subMedicalInstitution of freeTextSubMedicalInstitutionList"
              [value]="subMedicalInstitution"
              >{{ subMedicalInstitution.department }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <hr />

    <mat-label class="nnhcpLabel">專業別*</mat-label>
    <mat-form-field appearance="outline">
      <mat-select [(value)]="selectedJob" [disabled]="hcpItemDataSource === 'IQVIA'">
        <mat-option value="">請選擇</mat-option>
        <mat-option *ngFor="let job of jobList" [value]="job">{{ job.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-label class="nnhcpLabel">同意接收領域相關資訊*</mat-label>
    <div class="territoryDiv">
      <div class="territoryOptionDiv">
        <mat-checkbox color="primary" [(ngModel)]="diabetesRelatedField">糖尿病</mat-checkbox>
        <div class="territoryOptionImgDiv">
          <img src="assets/images/hcp/糖尿病.svg" />
        </div>
      </div>
      <div class="territoryOptionDiv">
        <mat-checkbox color="primary" [(ngModel)]="hemophiliaRelatedField">血友病</mat-checkbox>
        <div class="territoryOptionImgDiv">
          <img src="assets/images/hcp/血友病.svg" />
        </div>
      </div>
      <div class="territoryOptionDiv">
        <mat-checkbox color="primary" [(ngModel)]="weightLossRelatedField">肥胖症</mat-checkbox>
        <div class="territoryOptionImgDiv">
          <img src="assets/images/hcp/肥胖症.svg" />
        </div>
      </div>
      <div class="territoryOptionDiv">
        <mat-checkbox color="primary" [(ngModel)]="growthHormoneRelatedField">生長激素治療</mat-checkbox>
        <div class="territoryOptionImgDiv">
          <img src="assets/images/hcp/生長激素治療.svg" />
        </div>
      </div>
    </div>

    <div class="saveBtnDiv">
      <button mat-flat-button class="saveBtn" [disabled]="!checkForm()" (click)="submit()">下一步</button>
    </div>
  </div>
</div>
