import { Component, Inject, OnInit } from '@angular/core';
import { generateQrCodeUrl } from '@aiii/aiii-shared-front-end/tools/generate-qr-code-url';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InviteHcpQrcodeData } from './typeing';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invite-hcp-qrcode',
  templateUrl: './invite-hcp-qrcode.component.html',
  styleUrls: ['./invite-hcp-qrcode.component.scss'],
})
export class InviteHcpQrcodeComponent implements OnInit {
  public inviteHcpQrCodeUrl!: string;
  public hcpRegisterUrl!: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: InviteHcpQrcodeData) {}

  ngOnInit(): void {
    // 產生 QRCode
    this.hcpRegisterUrl = `https://liff.line.me/${environment.nnhcpLiffId}/hcp/hcp-register?hcpKey=${this.data.hcpKey}`;
    const qrCodeUrl = generateQrCodeUrl(this.hcpRegisterUrl, 200);
    this.inviteHcpQrCodeUrl = qrCodeUrl;
  }

  downloadImage(url: string): void {
    const nUrl = new URL(url);
    nUrl.searchParams.set('openExternalBrowser', '1');
    window.open(nUrl.href, '_blank');
  }

  downloadQrcode() {
    // 將 inviteHcpQrCodeUrl 的圖片下載到裝置端
    const nUrl = new URL(this.inviteHcpQrCodeUrl);
    nUrl.searchParams.set('openExternalBrowser', '1');
    window.open(nUrl.href, '_blank');
  }
}
