<div class="pageWrapper">
  <div class="logoImgDiv">
    <img src="assets/images/shared/nn_logo_dark.svg" class="logoImg" />
  </div>
  <div>醫事人員 邀請 QR code</div>
  <div class="inviterInfoDiv">
    您將邀請{{ data.hcpName }} 掃描加入
    <hr />
  </div>
  <div class="qrcodeDiv">
    <img [src]="inviteHcpQrCodeUrl" class="qrcodeImg" />
  </div>
  <!-- <div class="downloadQrcodeDiv">
    <button class="downloadQrcodeBtn" mat-flat-button (click)="downloadQrcode()">
      <mat-icon>download</mat-icon>下載
    </button>
  </div> -->

  <div class="copyUrlDiv">
    <button
      class="copyUrlBtn"
      [cdkCopyToClipboard]="
        data.hcpName + ' 您好，誠摯邀請您加入諾和諾德官方LINE 2.0！立刻點擊以下連結加入 ' + hcpRegisterUrl
      "
      mat-flat-button
    >
      <mat-icon>content_copy</mat-icon>複製網址
    </button>
  </div>

  <div class="saveBtnDiv">
    <button mat-flat-button class="saveBtn" mat-dialog-close>確認</button>
  </div>
</div>
