import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { LiffService } from './shared/liff.service';

@Injectable({
  providedIn: 'root',
})

/**
 * 此 AuthGuard 主要是用來檢查是否有登入,並且統一取得 line profile
 */
export class AuthGuard implements CanActivate {
  constructor(private liffService: LiffService) {}
  /**
   * 預設 liffId
   */
  private liffId: string = environment.nnhcpLiffId;

  /**
   * 舊有的 provider liffId
   */
  private oldOALiffId: string = '1656464452-Xng97e5V';
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      // 本地測試不用阻擋
      if (location.href.includes('http://localhost:')) {
        const devLiffConfig = localStorage.getItem('dev-liff-config');
        console.log('dev liff cfg=', devLiffConfig);

        if (devLiffConfig) {
          this.liffService.profile = JSON.parse(localStorage.getItem('dev-liff-config') as string);
        }
        return true;
      } else if (location.href.includes('finance-system-checkin-offline-qr')) {
        // 財務人員建立報到 QRCode 頁為公開頁面，不做任何檢查
        const financeConfig = {
          userId: 'system',
          displayName: 'NN財務人員',
          pictureUrl: 'https://storage.googleapis.com/aiii-bot-platform-tw/system/nopic.jpg',
        };
        this.liffService.profile = financeConfig;

        return true;
      }

      const liffState = route.queryParams['liff.state'] || '';
      const site = route.queryParams['site'];
      if (!site) {
        throw new Error('必帶參數 site');
      }
      const params = liffState.split('?');

      // 處理 liff init 前的網址
      // 在 init 前會先進去這個判斷，初步判斷是不是 舊OA
      if (liffState) {
        if (params[1]) {
          const originalParams = new URLSearchParams(params[1]);
          const map: { [key: string]: string } = {};
          originalParams.forEach(function (value, key) {
            map[key] = value;
          });
          if (map['oldOA'] === 'true') {
            this.liffId = this.oldOALiffId;
          }
        }
      } else {
        // init 之後，因為 liff init 會轉址，因此會再次檢查是否是 舊OA
        const isOldOA = route.queryParams['oldOA'] === 'true';
        this.liffId = isOldOA ? this.oldOALiffId : environment.nnhcpLiffId;
      }

      // 路由若包含 'activity-user-generate-checkin-qr' 則為跳轉現場報到 liffId
      if (location.href.includes('activity-user-generate-checkin-qr')) {
        this.liffId = environment.nnhcpActivityUserGenerateCheckinQrLiffId;
      }

      // 路由若包含 'check-in-offline' 則為跳轉現場報名＋報到 QRCode liffId
      if (location.href.includes('check-in-offline')) {
        this.liffId = environment.nnhcpActivityCheckInOfflineLiffId;
      }

      console.log('this.liffId', this.liffId);
      // liff init 跟 login
      await this.liffService.initLiff(this.liffId, site);
      if (!this.liffService.isLoggedIn()) {
        this.liffService.login();
      }
      // 根據是否有 userId 來判斷是否可以進行登入
      return !!this.liffService.profile.userId;
    } catch (e) {
      console.log('AuthGuard error', e);
      return false;
    }
  }
}
