import { MedicalInstitution } from '@aiii/nn-hcp-types/project/medicalInstitution/medicalInstitution';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DefaultAns } from '@aiii/aiii-types/implementations/product/member/default/default-ans';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HcpService } from 'src/app/hcp/hcp.service';
import {
  AddFreeText,
  HcpRegistrationFormDataResult,
  MedicalInstitutionForm,
  SubMedicalInstitutionDefault,
} from './types';
import * as _ from 'lodash';
import { DataSource, HospitalLevel } from '@aiii/nn-hcp-types';
import { defaultFormData } from './default-form-data';
import { AddHcpValidationStrategy } from '../../../hcp/hcp-register/hcp-register-data/add-hcp-validation-strategy';
import { ValidationStrategy } from './validation-strategy.interface';
import { Job } from '@aiii/nn-hcp-types/project/job/job';
import { UpdateHcpValidationStrategy } from 'src/app/hcp/hcp-info/update-hcp-validation-strategy';

@Component({
  selector: 'app-hcp-registration-form',
  templateUrl: './hcp-registration-form.component.html',
  styleUrls: ['./hcp-registration-form.component.scss'],
})
export class HcpRegistrationFormComponent implements OnInit {
  /** 傳入的hcp資料 */
  @Input() public hcpItemData: any | undefined = undefined;
  @Input() public hcpItemOriginPhone: any | undefined = undefined;
  @Input() public action: 'add' | 'update' = 'add';
  @Input() public strategy: ValidationStrategy | undefined;
  @Input() public customText: { nextBtn: string } | undefined = undefined;

  @Output() public hcpRegistrationFormDataResult: EventEmitter<HcpRegistrationFormDataResult> = new EventEmitter();
  @ViewChild('medicalInstitutionNameInput', { static: true }) medicalInstitutionNameInput!: ElementRef;

  /** 醫療院所是否沒有重複 */
  public isMedicalInstitutionAllUniq: boolean = true;

  public finishLoading: boolean = false;
  public hcpItemDataSource: DataSource | undefined = undefined;
  public jobList: Job[] = [];

  public selectedCounty: any = '';
  public selectedDistrict: any = '';
  public selectedMedicalInstitution: any = '';
  public selectedSubMedicalInstitution: any = '';

  public diabetesRelatedField: boolean = false;
  public weightLossRelatedField: boolean = false;
  public hemophiliaRelatedField: boolean = false;
  public growthHormoneRelatedField: boolean = false;

  public oMedicalInstitutionList: MedicalInstitutionForm[] = []; // 原始醫療院所列表
  public medicalInstitutionList: any[] = []; // 醫療院所列表
  public autoCompleteMedicalInstitutionList: any[] = []; // 醫療院所列表
  public phone: string = '';
  public hcpName: string = '';
  public email: string = '';

  public selectedAddMedicalInstitution: boolean = false; // 是否開啟手動新增醫療院所

  // 手動新增醫療院所表單
  public freeText: any = {
    county: '',
    district: '',
    medicalInstitutionName: '',
    subMedicalInstitutionKey: '',
    selectedCounty: '',
    selectedDistrict: '',
    selectedSubMedicalInstitution: '',
    boundTerritoryDataSource: 'AIII-LIFF-Add',
    department: '',
  };

  public hospitalLevel: any | '' = '';

  public selectedJob: any = '';

  public countyList: {
    countyName: string;
    districtList: {
      districtName: string;
    }[];
  }[] = [];

  public hospitalLevelList: HospitalLevel[] = [
    '一般診所',
    '區域醫院',
    '單點醫美診所',
    '地區醫院',
    '基層診所',
    '藥局',
    '連鎖醫美診所',
    '醫學中心',
  ];

  public freeTextSubMedicalInstitutionList: any[] = [];

  public medicalInstitutionFormList: MedicalInstitutionForm[] = []; // 紀錄所有醫療院所表單資料

  constructor(public hcpService: HcpService) {
    this.generateCountyList();
  }

  async ngOnInit() {
    // 取得醫療院所清單
    [this.medicalInstitutionList, this.freeText.subMedicalInstitutionList, this.jobList] = await Promise.all([
      this.hcpService.getMedicalInstitutionList(),
      this.hcpService.getSubMedicalInstitutionDefaultSortList(),
      this.hcpService.getJobList(),
    ]);

    this.freeTextSubMedicalInstitutionList = (
      this.freeText.subMedicalInstitutionList as SubMedicalInstitutionDefault[]
    ).filter((o) => {
      return o.isDisplay;
    });

    // 如果有傳入hcp預設資料，則帶入表單欄位
    if (this.hcpItemData) {
      // console.log('有傳入hcp預設資料 hcpItemData => ', this.hcpItemData);
      this.hcpName = this.hcpItemData.name;
      this.hcpItemDataSource = this.hcpItemData.dataSource;
      this.email = this.hcpItemData.email;
      this.phone = this.hcpItemData.phone;

      this.diabetesRelatedField = this.hcpItemData.diabetesRelatedField || false;
      this.weightLossRelatedField = this.hcpItemData.weightLossRelatedField || false;
      this.hemophiliaRelatedField = this.hcpItemData.hemophiliaRelatedField || false;
      this.growthHormoneRelatedField = this.hcpItemData.growthHormoneRelatedField || false;

      this.jobList = this.jobList
        .filter((j) => {
          let isDisplay = true;
          //資料來源是IQVIA時，就選全部顯示，因為此資料不可編輯。
          if (this.hcpItemData.dataSource === 'IQVIA') {
            return isDisplay;
          }
          isDisplay = j.isDisplay;
          return isDisplay;
        })
        .sort((a, b) => {
          // 按照sort欄位中由大到小排序
          return a.sort - b.sort;
        });

      this.selectedJob = this.jobList.find((j) => j._key === this.hcpItemData.jobKey) || '';

      if (this.hcpItemData.freeText) {
        const selectedSubMedicalInstitution = this.freeText.subMedicalInstitutionList.find((o: any) => {
          return this.hcpItemData.freeText.subMedicalInstitutionKey === o._key && o.isDisplay;
        });

        this.freeText = this.hcpItemData.freeText;
        this.freeText.selectedSubMedicalInstitution = selectedSubMedicalInstitution;
        this.freeText.selectedCounty = this.countyList.find((c) => c.countyName === this.freeText.county) || '';
        this.freeText.selectedDistrict =
          this.freeText.selectedCounty?.districtList?.find((o: any) => {
            return o.districtName === this.freeText.district;
          }) || '';

        // console.log(' this.freeText => ', this.freeText);
        this.selectedAddMedicalInstitution = true;
      }

      // 拆解醫療院所為表單初始值
      if (this.hcpItemData.medicalInstitutionInfo.length) {
        this.oMedicalInstitutionList = this.hcpItemData.medicalInstitutionInfo.filter((info: any) => {
          return info['medicalInstitutionKey'];
        });
        // console.log('原始表單.oMedicalInstitutionList => ', this.oMedicalInstitutionList);

        for (let i = 0; i < this.hcpItemData.medicalInstitutionInfo.length; i++) {
          const info = this.hcpItemData.medicalInstitutionInfo[i];

          if (info['medicalInstitutionKey']) {
            // console.log('拆解醫療院所為表單初始值 ~ info => ', info);
            const formData: MedicalInstitutionForm = {
              inputFromHcpItem: true, // 來源為資料庫撈取的既有hcp
              boundTerritoryKey: info.boundTerritoryKey,
              boundTerritoryDataSource: info.boundTerritoryDataSource,
              county: info.county || '',
              department: info.department || '',
              district: info.district || '',
              hospitalLevel: info.hospitalLevel || null,
              medicalInstitutionKey: info.medicalInstitutionKey || '',
              medicalInstitutionName: '', // 待後續驗證成功後再帶入 info.medicalInstitutionName
              subMedicalInstitutionKey: info.subMedicalInstitutionKey || '',
              selectedCounty: '',
              selectedDistrict: '',
              selectedMedicalInstitution: '',
              selectedSubMedicalInstitution: '',
            };

            this.medicalInstitutionFormList.push(formData);
            this.chooseMedicalInstitutionByMedicalInstitutionKey(
              formData.medicalInstitutionKey,
              this.medicalInstitutionFormList.length - 1
            );
          }
        }
      }
      // 重置全域選擇的醫療院所列表為空
      this.autoCompleteMedicalInstitutionList = [];
    } else {
      // 若無傳入hcp預設資料，則新增一筆空白醫療院所表單
      this.addMedicalInstitution();
    }

    this.finishLoading = true;
  }

  /**
   *  從 aiii-types 取得縣市資料，組裝成縣市物件列表 countyList
   */
  generateCountyList() {
    console.log(DefaultAns['cityDistrict']);
    const countyList = Object.keys(DefaultAns['cityDistrict']);

    for (const countyName of countyList) {
      const districtList = DefaultAns['cityDistrict'][countyName] as string[];
      this.countyList.push({
        countyName: countyName,
        districtList: districtList.map((district) => {
          return {
            districtName: district.split(' ')[0],
          };
        }),
      });
    }
    // this.countyList.push({
    //   countyName: '其他',
    //   districtList: [{ districtName: '其他' }],
    // });
  }

  /** 有帶入hcpItem，選擇對應欄位內容顯示 */
  chooseMedicalInstitutionByMedicalInstitutionKey(medicalInstitutionKey: string, index: number) {
    const medicalInstitution = this.medicalInstitutionList.find((m) => m._key === medicalInstitutionKey);
    // console.log('HcpRegistrationFormComponent ~ medicalInstitution => ', medicalInstitution);

    if (medicalInstitution) {
      this.medicalInstitutionChange(medicalInstitution, index);
    }
  }

  countyChange(event: any, index: number | 'freeText') {
    // console.log('countyChange => ', event, index);
    if (index !== 'freeText') {
      this.medicalInstitutionFormList[index].selectedCounty = event;
      this.districtChange('', index);
    } else {
      this.freeText.selectedCounty = event;
      this.districtChange('', index);
    }
  }

  districtChange(event: any, index: number | 'freeText') {
    // console.log('districtChange => ', index, event);

    if (index !== 'freeText') {
      this.medicalInstitutionFormList[index].selectedDistrict = event;
      this.medicalInstitutionChange('', index);
    } else {
      this.freeText.selectedDistrict = event;
      this.medicalInstitutionChange('', index);
    }
  }

  setSubMedicalInstitution(medicalInstitutionKey: string, index: number | 'freeText') {
    if (index !== 'freeText') {
      this.hcpService
        .getSubMedicalInstitutionListByMedicalInstitutionKey(medicalInstitutionKey)
        .then((subMedicalInstitutionList) => {
          this.medicalInstitutionFormList[index].subMedicalInstitutionList = subMedicalInstitutionList
            .filter((j) => {
              let isDisplay = true;
              //資料來源是MW或IQVIA時，就選全部顯示，因為此資料不可編輯。
              if (this.medicalInstitutionFormList[index].boundTerritoryDataSource === 'IQVIA') {
                return isDisplay;
              }
              isDisplay = j.isDisplay;
              return isDisplay;
            })
            .sort((a, b) => {
              // 按照sort欄位中由大到小排序
              return a.sort - b.sort;
            });

          if (this.medicalInstitutionFormList[index].inputFromHcpItem) {
            const subMedicalInstitutionKey = this.medicalInstitutionFormList[index].subMedicalInstitutionKey;
            this.medicalInstitutionFormList[index].selectedSubMedicalInstitution =
              this.medicalInstitutionFormList[index].subMedicalInstitutionList.find(
                (s: any) => s._key === subMedicalInstitutionKey
              ) || '';
          }
        });
    }
    this.autoCompleteMedicalInstitutionList = [];
  }

  filterMedicalInstitutionList(inputName: string, index: number | 'freeText') {
    if (index !== 'freeText') {
      this.autoCompleteMedicalInstitutionList = this.medicalInstitutionList.filter((medicalInstitution) => {
        if (
          (!this.medicalInstitutionFormList[index].selectedCounty ||
            medicalInstitution.county === this.medicalInstitutionFormList[index].selectedCounty.countyName) &&
          (!this.medicalInstitutionFormList[index].selectedDistrict ||
            medicalInstitution.district.includes(
              this.medicalInstitutionFormList[index].selectedDistrict.districtName
            ) ||
            this.medicalInstitutionFormList[index].selectedDistrict.districtName.includes(
              medicalInstitution.district
            )) &&
          ((this.medicalInstitutionFormList[index].selectedCounty && !inputName) ||
            (inputName &&
              (medicalInstitution.name.includes(inputName) ||
                inputName.includes(medicalInstitution.name) ||
                medicalInstitution.aliasNameList?.some((aliasName: string) => aliasName.includes(inputName)))))
        ) {
          return true;
        }

        return false;
      });
    }
  }

  optionSelectedMedicalInstitution(event: MatAutocompleteSelectedEvent, index: number | 'freeText') {
    this.medicalInstitutionChange(event.option.value, index);
  }

  medicalInstitutionChange(event: any, index: number | 'freeText') {
    // console.log('medicalInstitutionChange => ', event, index);
    if (index !== 'freeText') {
      const originalMedicalInstitutionKey = this.medicalInstitutionFormList[index].selectedMedicalInstitution._key;
      this.medicalInstitutionFormList[index].selectedMedicalInstitution = event;

      this.medicalInstitutionFormList[index].medicalInstitutionName = event.name || '';

      const county = this.countyList.find(
        (c) => c.countyName === this.medicalInstitutionFormList[index].selectedMedicalInstitution.county
      );
      if (county) {
        this.medicalInstitutionFormList[index].selectedCounty = county;
        const district = county.districtList.find(
          (d) =>
            d.districtName.includes(this.selectedMedicalInstitution.district) ||
            this.medicalInstitutionFormList[index].selectedMedicalInstitution.district.includes(d.districtName)
        );
        if (district) {
          this.medicalInstitutionFormList[index].selectedDistrict = district;
        }
      }
      this.filterMedicalInstitutionList(this.medicalInstitutionFormList[index].medicalInstitutionName, index);
      if (originalMedicalInstitutionKey === event._key) {
        return;
      }

      this.medicalInstitutionFormList[index].hospitalLevel = null;

      // 如果是變更了醫院，形同新增，不能沿用SE相關設定 boundTerritoryKey＝''
      // console.log('重置boundTerritoryKey', this.medicalInstitutionFormList[index]);
      // this.medicalInstitutionFormList[index].boundTerritoryKey = '';

      if (this.medicalInstitutionFormList[index].selectedMedicalInstitution) {
        this.medicalInstitutionFormList[index].hospitalLevel =
          this.medicalInstitutionFormList[index].selectedMedicalInstitution.hospitalLevel;
        this.setSubMedicalInstitution(this.medicalInstitutionFormList[index].selectedMedicalInstitution._key, index);
      }
    }
  }

  onInputBlur(event: any, index: number | 'freeText') {
    setTimeout(() => {
      // console.log('onInputBlur => ', event.target.value, index);
      const inputMedicalInstitutionName = event.target.value;
      // console.log('1 ~ inputMedicalInstitutionName => ', inputMedicalInstitutionName);

      if (index !== 'freeText') {
        if (
          inputMedicalInstitutionName &&
          (this.medicalInstitutionFormList[index].selectedMedicalInstitution === '' ||
            inputMedicalInstitutionName !== this.medicalInstitutionFormList[index].selectedMedicalInstitution.name)
        ) {
          const medicalInstitution = this.autoCompleteMedicalInstitutionList[0] || '';
          // console.log('onInputBlur ~ medicalInstitution => ', medicalInstitution);
          this.medicalInstitutionChange(medicalInstitution, index);
        }
      }
    }, 300);
  }

  inputChange(event: any, index: number | 'freeText') {
    this.filterMedicalInstitutionList(event.target.value, index);
  }

  /**
   * 新增醫療院所
   * 若為全新註冊，則新增醫療院所和新增FreeText只能二選一
   */
  addMedicalInstitution() {
    // console.log('＋新增醫療院所 addMedicalInstitution');
    if (
      this.action === 'add' &&
      !this.hcpItemData &&
      (this.selectedAddMedicalInstitution || this.medicalInstitutionFormList.length > 0)
    ) {
      this._btnDelInfoField('freeText', true);
    }
    const newFormData = _.cloneDeep(defaultFormData);
    this.medicalInstitutionFormList.push(newFormData);
    // console.log('this.selectedAddMedicalInstitution => ', this.selectedAddMedicalInstitution);
  }

  /**
   * FreeText 手動新增院所資料
   * 若為全新註冊，則新增醫療院所和新增FreeText只能二選一
   */
  addFreeTextMedicalInstitution() {
    // console.log('＋手動新增院所資料 addFreeTextMedicalInstitution');
    if (this.action === 'add' && !this.hcpItemData) {
      this._btnDelInfoField(0, true);
    }
    this.selectedAddMedicalInstitution = !this.selectedAddMedicalInstitution;
  }

  _btnDelInfoField(index: number | 'freeText', noAlert?: boolean) {
    if (!noAlert) {
      if (confirm('確認刪除')) {
        if (index !== 'freeText') {
          this.medicalInstitutionFormList.splice(index, 1);
        } else {
          this.selectedAddMedicalInstitution = !this.selectedAddMedicalInstitution;
        }
      }
      this.checkForm();
    } else {
      if (index !== 'freeText') {
        this.medicalInstitutionFormList.splice(index, 1);
      } else {
        this.selectedAddMedicalInstitution = !this.selectedAddMedicalInstitution;
      }
    }
  }

  /**
   * 表單必填檢查
   * @return {boolean} false=> 未填寫完整, true=>填寫完整
   */
  checkForm(): boolean {
    // 必填：基本資料，姓名、電話、職稱
    const isBasicHcpDataCheck = this.hcpName === '' || this.phone === '' || this.selectedJob === '';

    if (isBasicHcpDataCheck) {
      return false;
    }

    // 個資同意至少勾選一項
    if (
      !this.diabetesRelatedField &&
      !this.weightLossRelatedField &&
      !this.hemophiliaRelatedField &&
      !this.growthHormoneRelatedField
    ) {
      return false;
    }

    if (this.strategy) {
      const validateData = {
        formData: this.medicalInstitutionFormList,
        freeText: this.selectedAddMedicalInstitution ? this.freeText : null,
        hcpItem: this.hcpItemData ? this.hcpItemData : null,
      };

      this.isMedicalInstitutionAllUniq = this.strategy.checkUniqueMedicalInstitutionInfoList(validateData.formData);

      return this.strategy.validate(validateData.formData, validateData.freeText, validateData.hcpItem);
    }

    return false;
  }

  submit() {
    console.log('submit');
    //去除空白
    this.phone = this.phone.trim();
    this.email = this.email.trim();
    this.hcpName = this.hcpName.trim();

    if (!/^[\u4E00-\u9FA5A-Za-z0-9-,]+$/.test(this.hcpName)) {
      alert('姓名不可有特殊符號');
      return;
    }

    // 電話格式需滿足 09XX XXX XXX
    if (!this.phone.match(/^09\d{2}\s?\d{3}\s?\d{3}$/)) {
      alert('請輸入 09 開頭的 10 碼手機號碼');
      return;
    }

    //檢查 email 格式
    if (this.email !== '' && !this.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
      alert('請輸入正確的 email 格式');
      return;
    }

    if (!this.checkForm()) {
      alert('請填寫完整資料');
      return;
    }

    this.medicalInstitutionFormList.forEach((medicalInstitutionForm) => {
      medicalInstitutionForm.medicalInstitutionKey = medicalInstitutionForm.selectedMedicalInstitution._key;
      medicalInstitutionForm.subMedicalInstitutionKey = medicalInstitutionForm.selectedSubMedicalInstitution._key;
      medicalInstitutionForm.county = medicalInstitutionForm.selectedCounty.countyName;
      medicalInstitutionForm.district = medicalInstitutionForm.selectedDistrict.districtName;
      medicalInstitutionForm.department = medicalInstitutionForm.selectedSubMedicalInstitution.department;
      medicalInstitutionForm.hospitalLevel = medicalInstitutionForm.selectedMedicalInstitution.hospitalLevel;
    });

    const hcpRegistrationFormDataResultData: HcpRegistrationFormDataResult = {
      jobKey: this.selectedJob._key,
      phone: this.phone,
      hcpName: this.hcpName,
      email: this.email,
      dataSource: this.hcpItemDataSource!,
      diabetesRelatedField: this.diabetesRelatedField,
      weightLossRelatedField: this.weightLossRelatedField,
      hemophiliaRelatedField: this.hemophiliaRelatedField,
      growthHormoneRelatedField: this.growthHormoneRelatedField,
      medicalInstitutionFormList: this.medicalInstitutionFormList,
    };

    // 如果有開啟手動新增醫療院所，則將手動新增的醫療院所資料存入 freeText
    if (this.selectedAddMedicalInstitution) {
      this.freeText.county = this.freeText.selectedCounty.countyName;
      this.freeText.district = this.freeText.selectedDistrict.districtName;
      this.freeText.subMedicalInstitutionKey = this.freeText.selectedSubMedicalInstitution._key;
      this.freeText.department = this.freeText.selectedSubMedicalInstitution.department;
      hcpRegistrationFormDataResultData.freeText = this.freeText;
    }

    // console.log('送出表單內容 => ', hcpRegistrationFormDataResultData);
    this.hcpRegistrationFormDataResult.emit(hcpRegistrationFormDataResultData);
  }
}
