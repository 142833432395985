import { environmentBase } from './environment-base';

export const environment = {
  ...environmentBase,
  oaId: '@732ipkar',
  nnhcpLiffId: '2004096315-gd07APXG',
  nnhcpActivityUserGenerateCheckinQrLiffId: '2004096315-rGvwe7LX', // 線下活動報到
  nnhcpActivityCheckInOfflineLiffId: '2004096315-vLmqoVdM', // 現場活動報名＋報到 QRCode
  production: false,
  firebase: {
    apiKey: 'AIzaSyDU1KGqlcJ87gfuUH2aerjfhJglkbUpn1c',
    authDomain: 'aiii-developer.firebaseapp.com',
    databaseURL: 'https://aiii-developer.firebaseio.com',
    projectId: 'aiii-developer',
    storageBucket: 'aiii-developer.appspot.com',
    messagingSenderId: '700749202278',
  },
  firebaseConfig: {
    us: {
      apiKey: 'AIzaSyB6RTpIxVI7OWI2DpIJtEmpGFtz1joJPxo',
      authDomain: 'aiii-developer.firebaseapp.com',
      databaseURL: 'https://aiii-developer.firebaseio.com',
      projectId: 'aiii-developer',
      storageBucket: 'aiii-developer.appspot.com',
      messagingSenderId: '700749202278',
      appId: '1:700749202278:web:d970c23d2a750e0641fea7',
    },
    taiwan: {
      apiKey: 'AIzaSyC5K17qCPu1R-m-VKSvH7AG8LSIiDH5HpQ',
      authDomain: 'aiii-developer-tw.firebaseapp.com',
      databaseURL: 'https://aiii-developer-tw.firebaseio.com',
      projectId: 'aiii-developer-tw',
      storageBucket: 'aiii-developer-tw.appspot.com',
      messagingSenderId: '296524928200',
    },
    testAllan: {
      apiKey: 'AIzaSyATSDeV6-Xrv2JWz0qad4QP1tCX36VmHyU',
      authDomain: 'aiii-testallan.firebaseapp.com',
      projectId: 'aiii-testallan',
      storageBucket: 'aiii-testallan.appspot.com',
      messagingSenderId: '648546485642',
      appId: '1:648546485642:web:cbd7b43259a27cd52ef473',
      measurementId: 'G-57V71EX8MY',
    },
    nnhcp: {
      apiKey: 'AIzaSyD7dKxH5wj3HgnICiIzuYZjnLvZIZtfA4U',
      authDomain: 'nn-hcp-stage.firebaseapp.com',
      projectId: 'nn-hcp-stage',
      storageBucket: 'nn-hcp-stage.appspot.com',
      messagingSenderId: '699862024755',
      appId: '1:699862024755:web:8f6d11485f5bba1d23d946',
      measurementId: 'G-ZJKWHHEH17',
    },
  },
  useOtherFirebaseProject: {
    sites: ['nnhcp'],
    modules: ['hcp', 'sales', 'activity'],
  },
  cloudrun: {
    api: 'https://api-v2-5uvz3vc6ga-de.a.run.app',
    platformPush: 'https://platform-push-v1-5uvz3vc6ga-de.a.run.app',
    platformPushAudienceClient: 'https://platform-push-audience-client-5uvz3vc6ga-de.a.run.app',
    basicWebhook: 'https://prod-line-dialogflow-5uvz3vc6ga-de.a.run.app',
    lottery: [
      'https://api-lottery-01-5uvz3vc6ga-de.a.run.app',
      'https://api-lottery-02-5uvz3vc6ga-de.a.run.app',
      'https://api-lottery-03-5uvz3vc6ga-de.a.run.app',
      'https://api-lottery-04-5uvz3vc6ga-de.a.run.app',
      'https://api-lottery-05-5uvz3vc6ga-de.a.run.app',
    ],
    couponOnline: 'https://api-coupon-online-5uvz3vc6ga-de.a.run.app',
    recommendation: 'https://recommendation-5uvz3vc6ga-de.a.run.app',
    liveStream: 'https://api-live-stream-5uvz3vc6ga-de.a.run.app',
    scratch: 'https://aiii-api-lottery-scratch-5uvz3vc6ga-de.a.run.app',
    apiMemberForm: 'https://api-member-form-5uvz3vc6ga-de.a.run.app',
    survey: 'https://aiii-api-survey-5uvz3vc6ga-de.a.run.app',
    nnhcp: 'https://project-nn-hcp-5uvz3vc6ga-de.a.run.app',
    activity: 'https://aiii-api-activity-5uvz3vc6ga-de.a.run.app',
  },
  gke: {
    excelDownload: 'https://stage-internal-api.aiii.ai/label/download-xlsx',
    internalApi: 'https://stage-internal-api.aiii.ai',
    internalApiKey: '',
    liffUrl: 'https://stage-liff.aiii.ai',
  },
};
