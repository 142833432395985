import { DataSource } from '@aiii/nn-hcp-types';

export const defaultFormData = {
  boundTerritoryKey: '',
  boundTerritoryDataSource: "AIII-LIFF-Add" as DataSource,
  county: '',
  department: '',
  inputFromHcpItem: false,
  district: '',
  hospitalLevel: null,
  medicalInstitutionKey: '',
  medicalInstitutionName: '',
  subMedicalInstitutionKey: '',
  selectedCounty: '',
  selectedDistrict: '',
  selectedMedicalInstitution: '',
  selectedSubMedicalInstitution: '',
};
