<div class="pageWrapper">
  <div class="dialogTitle" *ngIf="data.title">{{data.title}}</div>
  <div>{{data.content}}</div>

  <div class="okDiv" *ngIf="data.okText">
    <button type="button" class="okBtn" mat-flat-button (click)="clickOk()">
      {{data.okText}}
    </button>
  </div>

  <div class="cancelDiv" *ngIf="data.cancelText">
    <button type="button" class="cancelBtn" mat-button (click)="clickCancel()">
      {{data.cancelText}}
    </button>
  </div>
</div>