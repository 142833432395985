import { InjectionToken, Provider } from '@angular/core';
import { environmentBase } from '../../../environments/environment-base';

interface SharedCache {
  [functionName: string]: {
    [key: string]: {
      data: any;
      timestamp: Date;
      isFinish: boolean;
    }
  };
}

export const sharedCache: SharedCache = {};

export const CONSOLE_SHARED = new InjectionToken<string>(
  'Console Shared',
);

export class ConsoleShared {
  get site() {
    return environmentBase.isProductionModule ? `${new URL(window.location.href).searchParams.get('site') || ''}` : environmentBase.defaultSite;
  }

  async sleep(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  async getCacheData(functionName: string, key: string, func: () => Promise<any>, cacheTime = 60, timeout = 60): Promise<any> {

    const functionStartTime = new Date();

    if (!sharedCache[functionName]) {
      sharedCache[functionName] = {};
    }

    switch (true) {
      case func == undefined: // 若未填寫自訂 function，表示資料來源由其他地方執行完成。等待它處的的動作執行完畢即獲取資料，直到超過指定的 timeout 時間。(會忽略掉 cacheTime)
        if (!sharedCache[functionName][key]?.isFinish) {
          while (!sharedCache[functionName][key]?.isFinish && (new Date().getTime() - functionStartTime.getTime() < timeout * 1000)) {
            await this.sleep(100);
          }
          if (new Date().getTime() - functionStartTime.getTime() >= timeout * 1000) {
            throw Error('timeout');
          }
        }
        break;

      case !sharedCache[functionName][key] // 尚未快取或快取過期，則重新執行自訂 function
      || (new Date().getTime() - sharedCache[functionName][key].timestamp.getTime() > cacheTime * 1000):
        sharedCache[functionName][key] = {
          data: null,
          timestamp: new Date(),
          isFinish: false,
        };
        sharedCache[functionName][key].data = await func();
        sharedCache[functionName][key].timestamp = new Date();
        sharedCache[functionName][key].isFinish = true;
        break;

      case !sharedCache[functionName][key]?.isFinish: // 自訂 function 尚未完成時
        while (!sharedCache[functionName][key].isFinish && (new Date().getTime() - functionStartTime.getTime() < timeout * 1000)) {
          await this.sleep(100);
        }
        if (new Date().getTime() - functionStartTime.getTime() >= timeout * 1000) {
          throw Error('timeout');
        }
        break;

      default:

    }

    return sharedCache[functionName][key].data;
  }

}

export const CONSOLE_SHARED_PROVIDER: Provider = {
  provide: CONSOLE_SHARED,
  deps: [],
  useClass: ConsoleShared,
};
