import { Inject, Injectable } from '@angular/core';
import { FIREBASE_REFERENCES } from '../shared/methods/firebase.module';
import { AngularFirestore } from '@angular/fire/firestore';
import { HCP } from '@aiii/nn-hcp-types/project/role/hcp';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SharedService } from '../shared/shared.service';
import { environment } from 'src/environments/environment';
import { CheckHcpExistRequest } from '@aiii/nn-hcp-types/project/cloud-run/hcp/check-hcp-exist-request';
import { RegisterHcpRequest } from '@aiii/nn-hcp-types/project/cloud-run/hcp/register-hcp-request';
import { AiiiError } from '@aiii/aiii-types';
import { HcpItemResponse } from './types';
import {
  HcpRegistrationFormDataResult,
  MedicalInstitutionForm,
  MedicalInstitutionInfoAPIRequest,
} from '../shared/components/hcp-registration-form/types';
import * as _ from 'lodash';
import { Job } from '@aiii/nn-hcp-types/project/job/job';

@Injectable({
  providedIn: 'root',
})
export class HcpService {
  private path = '/sites/nnhcp/roleHcp';
  public jobList: Job[] = [];

  constructor(
    @Inject(FIREBASE_REFERENCES.TAIWAN_FIRESTORE)
    private readonly twFirestore: AngularFirestore,
    private sharedService: SharedService,
    private http: HttpClient
  ) {}

  async getMedicalInstitution(medicalInstitutionKey: string) {
    let medicalInstitution: any | undefined = undefined;
    const medicalInstitutionDoc = await this.twFirestore
      .collection('/sites/nnhcp/medicalInstitution')
      .doc(medicalInstitutionKey)
      .ref.get();

    if (medicalInstitutionDoc.exists) {
      medicalInstitution = medicalInstitutionDoc.data() as any;
    }

    return medicalInstitution;
  }

  /** 取得所有ＮＮ的醫療院所  */
  async getMedicalInstitutionList() {
    const medicalInstitutionCol = await this.twFirestore.collection('/sites/nnhcp/medicalInstitution').ref.get();
    return medicalInstitutionCol.docs.map((doc) => doc.data() as any);
  }

  /** 取得排序好的 default 科別  */
  async getSubMedicalInstitutionDefaultSortList() {
    const subMedicalInstitutionCol = await this.twFirestore
      .collection('/sites/nnhcp/subMedicalInstitutionDefault')
      .ref.get();
    return subMedicalInstitutionCol.docs
      .map((doc) => doc.data() as any)
      .sort((a, b) => {
        return a.sort - b.sort;
      });
  }

  async getSubMedicalInstitutionListByMedicalInstitutionKey(medicalInstitutionKey: string) {
    const subMedicalInstitutionCol = await this.twFirestore
      .collection('/sites/nnhcp/subMedicalInstitution')
      .ref.where('medicalInstitutionKey', '==', medicalInstitutionKey)
      .get();
    return subMedicalInstitutionCol.docs.map((doc) => doc.data() as any);
  }

  async getHcpItem(hcpKey: string): Promise<HcpItemResponse> {
    return new Promise((resolve, reject) => {
      this.sharedService.ajaxJsonPost(`${environment.cloudrun.nnhcp}/hcp/item`, { id: hcpKey }).subscribe({
        next: (rep: any) => {
          resolve(rep.body);
        },
        error: (err: any) => {
          reject(err.error);
        },
      });
    });
  }

  /**
   * 根據 舊user 取得 HCP
   * @param oldLineUserId
   * @returns
   */
  async getByOldLineUserId(oldLineUserId: string): Promise<HCP | undefined> {
    const hcpsRef = await this.twFirestore.firestore
      .collection(this.path)
      .where('oldLineUserId', '==', oldLineUserId)
      .get();
    const hcps: HCP[] = hcpsRef.docs.map((hcpRef) => hcpRef.data() as HCP);
    if (!hcps.length) {
      throw new Error('找不到此使用者');
    }

    // 有資料的話取得第一筆
    return hcpsRef.size ? hcps[0] : undefined;
  }

  /**
   * 新舊 HCP 轉移
   * @param oldLineUserId
   * @param newLineUserId
   * @returns
   */
  async transferHcp(
    oldLineUserId: HCP['oldLineUserId'],
    newLineUserId: HCP['LINEUserId']
  ): Promise<{ pushMessageText: string; alertText: string; replaceToPath: string }> {
    try {
      return (await this.http
        .post(`${environment.cloudrun.nnhcp}/hcp/transfer-hcp`, {
          oldLineUserId,
          newLineUserId,
        })
        .toPromise()) as { pushMessageText: string; alertText: string; replaceToPath: string };
    } catch (e) {
      throw new Error((e as HttpErrorResponse).error);
    }
  }
  async getRoleHcpByLINEUserId(LINEUserId: string) {
    let roleHcp: any | undefined = undefined;
    const roleHcpCol = await this.twFirestore
      .collection('/sites/nnhcp/roleHcp')
      .ref.where('LINEUserId', '==', LINEUserId)
      .get();

    if (!roleHcpCol.empty) {
      roleHcp = roleHcpCol.docs[0].data() as any;
    }

    return roleHcp;
  }

  /**
   * 確認 HCP 是否存在，回傳 HCP id 或是 undefined
   * @param checkHcpExistRequest
   * @returns
   */
  checkHcpExist(checkHcpExistRequest: CheckHcpExistRequest): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sharedService
        .ajaxJsonPost(`${environment.cloudrun.nnhcp}/hcp/check-hcp-exist`, checkHcpExistRequest)
        .subscribe({
          next: (rep: any) => {
            resolve(rep.body as any);
          },
          error: (err: any) => {
            reject(err.error);
          },
        });
    });
  }

  getHcpListByUserIdList(userIdList: string[]): Promise<any> {
    const hcpListByUserIdListRequest = {
      userIdList: userIdList,
    };
    return new Promise((resolve, reject) => {
      this.sharedService
        .ajaxJsonPost(`${environment.cloudrun.nnhcp}/hcp/list-by-user-id-list`, hcpListByUserIdListRequest)
        .subscribe({
          next: (rep: any) => {
            resolve(rep.body as any);
          },
          error: (err: any) => {
            reject(err.error);
          },
        });
    });
  }

  async getHcpFreeText(hcpKey: string): Promise<any> {
    const roleHcp = await this.twFirestore.doc(`/sites/nnhcp/roleHcp/${hcpKey}`).ref.get();
    return roleHcp.data()?.['freeText'] || null;
  }

  async checkIsHcpExistByPhone(phone: string): Promise<boolean> {
    const roleHcpCol = await this.twFirestore.collection('/sites/nnhcp/roleHcp').ref.where('phone', '==', phone).get();

    return !roleHcpCol.empty;
  }

  /**
   * 註冊 HCP
   * @param registerHcpRequest
   * 成功回傳 { hcpKey: string; verificationStatus: string; message: string}
   * @returns Promise<any>
   */
  registerHcp(registerHcpRequest: RegisterHcpRequest): Promise<any> {
    console.log('註冊 HCP', registerHcpRequest);
    return new Promise((resolve, reject) => {
      this.sharedService.ajaxJsonPost(`${environment.cloudrun.nnhcp}/hcp/register-hcp`, registerHcpRequest).subscribe({
        next: (rep: any) => {
          resolve(rep.body as any);
        },
        error: (err: any) => {
          reject(new AiiiError(err.status, err.error || err.statusText));
        },
      });
    });
  }

  /**
   * 編輯更新 HCP 資料
   * @param updateHcpInfoData
   * @returns
   */
  updateHcpInfo(updateHcpInfoData: Partial<RegisterHcpRequest>): Promise<any> {
    console.log('API 更新 HCP 資料', updateHcpInfoData);
    return new Promise((resolve, reject) => {
      this.sharedService
        .ajaxJsonPost(`${environment.cloudrun.nnhcp}/hcp/update-hcp-info-and-bound-option`, updateHcpInfoData)
        .subscribe({
          next: (rep: any) => {
            resolve(rep.body as any);
          },
          error: (err: any) => {
            reject(new AiiiError(err.status, err.error || err.statusText));
          },
        });
    });
  }

  /**
   * 驗證參加活動且已經綁定 LineUserId 的 HCP，讓此 HCP 從未驗證變成已驗證
   * @param verifyActivityExistHcpRequest
   * @returns
   */
  verifyActivityExistHcp(verifyActivityExistHcpRequest: any) {
    return new Promise((resolve, reject) => {
      this.sharedService
        .ajaxJsonPost(`${environment.cloudrun.nnhcp}/hcp/verify-activity-exist-hcp`, verifyActivityExistHcpRequest)
        .subscribe({
          next: (rep: any) => {
            resolve(rep.body as any);
          },
          error: (err: any) => {
            reject(err.error);
          },
        });
    });
  }

  updatePersonalInfoConsentForm(updatePersonalInfoConsentFormRequest: {
    hcpKey: any;
    diabetesRelatedField: boolean;
    weightLossRelatedField: boolean;
    hemophiliaRelatedField: boolean;
    growthHormoneRelatedField: boolean;
  }) {
    return new Promise((resolve, reject) => {
      this.sharedService
        .ajaxJsonPost(
          `${environment.cloudrun.nnhcp}/hcp/update-hcp-personal-info-consent-form`,
          updatePersonalInfoConsentFormRequest
        )
        .subscribe({
          next: (rep: any) => {
            resolve(rep.body as any);
          },
          error: (err: any) => {
            reject(err.error);
          },
        });
    });
  }

  createHcp(createHcpRequest: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sharedService.ajaxJsonPost(`${environment.cloudrun.nnhcp}/hcp/create-hcp`, createHcpRequest).subscribe({
        next: (rep: any) => {
          resolve(rep.body as any);
        },
        error: (err: any) => {
          reject(err.error);
        },
      });
    });
  }

  async getJobList() {
    if (this.jobList.length) {
      return this.jobList;
    }
    const jobCol = await this.twFirestore.collection('/sites/nnhcp/job').ref.get();

    if (!jobCol.empty) {
      this.jobList = jobCol.docs.map((doc) => doc.data() as Job);
    }

    return this.jobList;
  }

  /**
   * 以更新後的醫療院所為主進行比對，並回傳最後要打API更新的醫療院所格式
   */
  refactorMedicalInstitutionInfo(
    medicalInstitutionInfoList: HcpRegistrationFormDataResult['medicalInstitutionFormList'],
    originMedicalInstitutionList?: HcpItemResponse['medicalInstitutionInfo'] | undefined
  ): MedicalInstitutionInfoAPIRequest[] {
    // console.log('start refactorMedicalInstitutionInfo');

    const newMedicalList = medicalInstitutionInfoList;
    const oldMedicalList = _.cloneDeep(originMedicalInstitutionList);

    let finalUpdateList: {
      medicalInstitutionKey: string;
      subMedicalInstitutionKey: string;
      boundTerritoryKey?: string;
      remove?: boolean; // 刪除時才會有
    }[] = [];

    // 如果原始資料中有醫療院所資料，才進行比對
    if (oldMedicalList?.length) {
      // console.log('原始醫療院所 => ', oldMedicalList);
      // console.log('更新醫療院所 => ', newMedicalList);

      // 進行比對   遍歷每個更新的醫療院所
      for (let i = 0; i < newMedicalList.length; i++) {
        const newMedical = newMedicalList[i];
        console.log(i, ' newMedical => ', newMedical);

        // 如果更新的資料 在原始資料中 比對到有相同的 medicalInstitutionKey 和 subMedicalInstitutionKey
        const oldMedical = oldMedicalList.find(
          (o) =>
            o.medicalInstitutionKey === newMedical.medicalInstitutionKey &&
            o.subMedicalInstitutionKey === newMedical.subMedicalInstitutionKey
        );

        if (oldMedical) {
          // 不做更動，移除原始醫療院所陣列中的該筆資料
          oldMedicalList.splice(oldMedicalList.indexOf(oldMedical), 1);
        } else {
          // 新增到最後要打API更新的醫療院所
          finalUpdateList.push({
            medicalInstitutionKey: newMedical.medicalInstitutionKey,
            subMedicalInstitutionKey: newMedical.subMedicalInstitutionKey,
          });
        }
      }

      // 刪除
      // console.log('比對後剩下的原始內容需要刪除 ~ oldMedicalList => ', oldMedicalList);
      for (const o of oldMedicalList) {
        if (o.boundTerritoryKey) {
          finalUpdateList.push({
            medicalInstitutionKey: o.medicalInstitutionKey,
            subMedicalInstitutionKey: o.subMedicalInstitutionKey,
            boundTerritoryKey: o.boundTerritoryKey,
            remove: true,
          });
        }
      }

      // console.log('最後要打API更新的醫療院所 => ', finalUpdateList);
      return finalUpdateList;
    } else {
      // 如果原始資料中沒有醫療院所資料，直接回傳更新的醫療院所
      finalUpdateList = newMedicalList.map((m) => {
        return {
          medicalInstitutionKey: m.medicalInstitutionKey,
          subMedicalInstitutionKey: m.subMedicalInstitutionKey,
        };
      });

      return finalUpdateList;
    }
  }

  /**
   * 產生最後要打API更新的醫療院所格式 MedicalInstitutionInfoAPIRequest 格式
   * @param hcpRegisterDataResult
   */
  generateMedicalInstitutionInfo(
    hcpRegisterDataResult: HcpRegistrationFormDataResult
  ): Partial<MedicalInstitutionInfoAPIRequest>[] {
    if (hcpRegisterDataResult.medicalInstitutionFormList.length > 0) {
      return hcpRegisterDataResult.medicalInstitutionFormList.map((form: MedicalInstitutionForm) => {
        return {
          department: form.department,
          subMedicalInstitutionKey: form.subMedicalInstitutionKey,
          medicalInstitutionKey: form.medicalInstitutionKey,
          medicalInstitutionName: form.medicalInstitutionName,
          hospitalLevel: form.hospitalLevel,
          boundTerritoryKey: form.boundTerritoryKey || '',
          remove: form.remove || false,
        };
      });
    }
    return [];
  }

  /**
   * 取得手機號碼的的驗證狀態：是否已經被 HCP 驗證過
   * 若已經被驗證過,回傳手機號碼被驗證在哪個 roleHcp 相關資訊
   * @param phone
   */
  async checkHcpPhoneValidated(phone: string): Promise<any> {
    console.log('checkHcpPhoneValidated', phone, 'url', `${environment.cloudrun.nnhcp}/hcp/check-hcp-phone-validated`);
    return new Promise((resolve, reject) => {
      this.sharedService
        .ajaxJsonPost(`${environment.cloudrun.nnhcp}/hcp/check-hcp-phone-validated`, { phone: phone })
        .subscribe({
          next: (rep: any) => {
            resolve(rep.body as any);
          },
          error: (err: any) => {
            reject(err.error);
          },
        });
    });
  }
}
