import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { VerifyPhoneService } from './verify-phone.service';
import { VerifyPhoneResult } from './typeing';
import * as firebase from 'firebase/app';
import { FIREBASE_REFERENCES } from '../../methods/firebase.module';
import { AngularFireAuth } from '@angular/fire/auth';
import { LiffService } from '../../liff.service';

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss'],
  providers: [VerifyPhoneService],
})
export class VerifyPhoneComponent implements AfterViewInit {
  @Input() phone = '';
  @Input() optResendTime = 30;
  @Output() verifyPhoneResult: EventEmitter<VerifyPhoneResult> = new EventEmitter();
  @Output() confirmationResult: EventEmitter<firebase.auth.ConfirmationResult> = new EventEmitter();

  public isProgress = true;
  public timer = 30;
  public errorMessage = '';
  public verificationCode = '';
  private verificationId = '';
  private recaptchaVerifier!: firebase.auth.RecaptchaVerifier;
  private url;

  constructor(
    @Inject(FIREBASE_REFERENCES.US_FIREAUTH) private readonly usFireAuth: AngularFireAuth,
    private liffService: LiffService
  ) {
    this.url = new URL(window.location.href);
  }

  async ngAfterViewInit() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', { size: 'invisible' });
    await this.sendPhoneCode();
  }

  checkForm() {
    return !!this.verificationCode;
  }

  otpTimer(time: number) {
    this.timer = time;
    const interval = setInterval(() => {
      this.timer--;
      if (this.timer < 0) clearInterval(interval);
    }, 1000);
  }

  checkVerificationCodeIsPhone() {
    return this.verificationCode.match(/^09\d{2}\s?\d{3}\s?\d{3}$/);
  }

  async sendPhoneCode() {
    this.isProgress = true;
    // 如果網址包含 localhost 就不發送驗證碼
    if (location.href.includes('localhost')) {
      this.isProgress = false;
      return;
    }

    this.liffService.log(
      this.url.searchParams.get('site') || '',
      this.liffService.profile.userId,
      'verifyPhoneSendPhoneCode',
      this.phone
    );

    try {
      this.otpTimer(this.optResendTime);
      const inputPhone = `+886${this.phone.substring(1)}`;
      const confirmationResult = await this.usFireAuth.auth.signInWithPhoneNumber(inputPhone, this.recaptchaVerifier);
      this.verificationId = confirmationResult.verificationId;
    } catch (error: any) {
      console.error(error);
      this.errorMessage = '驗證失敗，請提供此畫面截圖及會員資訊給我們為您處理';
      this.verifyPhoneResult.emit({
        result: 'fail',
        phoneVerificationCode: this.verificationCode,
        resultMessage: this.errorMessage,
      });
    } finally {
      this.isProgress = false;
    }
  }

  async _btnOtpVerify() {
    this.isProgress = true;
    this.errorMessage = '';
    try {
      // 後門通過規則
      if (this.verificationCode.trim() === this.phone.trim()) {
        this.verifyPhoneResult.emit({
          result: 'success',
          phoneVerificationCode: this.verificationCode,
          resultMessage: '',
        });
      } else {
        const credentials = firebase.auth.PhoneAuthProvider.credential(this.verificationId, this.verificationCode);
        await this.usFireAuth.auth.signInWithCredential(credentials);
        this.verifyPhoneResult.emit({
          result: 'success',
          phoneVerificationCode: this.verificationCode,
          resultMessage: '',
        });
      }

      this.liffService.log(
        this.url.searchParams.get('site') || '',
        this.liffService.profile.userId,
        'verifyPhoneSuccess',
        this.phone,
        JSON.stringify({
          result: 'success',
          phoneVerificationCode: this.verificationCode,
          resultMessage: '',
        })
      );
    } catch (error: any) {
      console.error(error);
      const errorCodeMap: { [code: string]: string } = {
        'auth/invalid-verification-code': '驗證碼錯誤',
        'auth/code-expired': '驗證碼已過期',
      };
      this.errorMessage = errorCodeMap[error.code] || error.message;
      this.verifyPhoneResult.emit({
        result: 'fail',
        phoneVerificationCode: this.verificationCode,
        resultMessage: this.errorMessage,
      });
      this.liffService.log(
        this.url.searchParams.get('site') || '',
        this.liffService.profile.userId,
        'verifyPhoneError',
        this.phone,
        JSON.stringify({
          result: 'fail',
          phoneVerificationCode: this.verificationCode,
          resultMessage: this.errorMessage,
        })
      );
    } finally {
      this.isProgress = false;
    }
  }

  _btnPrev() {
    this.verifyPhoneResult.emit({
      result: 'cancel',
      phoneVerificationCode: this.verificationCode,
      resultMessage: '',
    });
  }
}
