import { InjectionToken, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ENV, ENV_CONFIG } from '../interface/firebase-env';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';

export function AngularFirestoreFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  const site = 'nnhcp';
  const module = window.location.pathname.split('/')[1];

  projectId =
    environment.useOtherFirebaseProject.modules.includes(module) &&
    environment.useOtherFirebaseProject.sites.includes(site)
      ? site
      : projectId;

  return new AngularFirestore(env.firebaseConfig[projectId], projectId, false, null, platformId, zone, null);
}

export function AngularFireAuthFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  const url = new URL(window.location.href);
  const site = 'nnhcp';
  const module = window.location.pathname.split('/')[1];

  projectId =
    environment.useOtherFirebaseProject.modules.includes(module) &&
    environment.useOtherFirebaseProject.sites.includes(site)
      ? site
      : projectId;

  return new AngularFireAuth(env.firebaseConfig[projectId], projectId, platformId, zone);
}

export function AngularFireStorageFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  return new AngularFireStorage(
    env.firebaseConfig[projectId],
    projectId,
    env.firebaseConfig[projectId].storageBucket,
    platformId,
    zone
  );
}

export function AngularFireFunctionshFactory(platformId: any, zone: NgZone, env: ENV, projectId: string) {
  return new AngularFireFunctions(
    env.firebaseConfig[projectId],
    projectId,
    platformId,
    zone,
    env.firebaseConfig[projectId].region!,
    null
  );
}

export const FIREBASE_PROJECT_US = new InjectionToken<ENV>('firebase project injector for us');
export const FIREBASE_PROJECT_TAIWAN = new InjectionToken<ENV>('firebase project injector for taiwan');

export const FIREBASE_REFERENCES = {
  US_FIRESTORE: 'project-us-firestore',
  US_FIRESTORAGE: 'project-us-firestorage',
  US_FIREAUTH: 'project-us-fireauth',
  US_FIREFUNCTIONS: 'project-us-functions',
  TAIWAN_FIRESTORE: 'project-taiwan-firestore',
  TAIWAN_FIRESTORAGE: 'project-taiwan-firestorage',
  TAIWAN_FIREAUTH: 'project-taiwan-fireauth',
  TAIWAN_FIREFUNCTIONS: 'project-taiwan-functions',
};

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    { provide: FIREBASE_PROJECT_US, useValue: 'us' },
    { provide: FIREBASE_PROJECT_TAIWAN, useValue: 'taiwan' },
    {
      provide: FIREBASE_REFERENCES.US_FIRESTORE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_US],
      useFactory: AngularFirestoreFactory,
    },
    {
      provide: FIREBASE_REFERENCES.TAIWAN_FIRESTORE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_TAIWAN],
      useFactory: AngularFirestoreFactory,
    },
    {
      provide: FIREBASE_REFERENCES.US_FIRESTORAGE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_US],
      useFactory: AngularFireStorageFactory,
    },
    {
      provide: FIREBASE_REFERENCES.TAIWAN_FIRESTORAGE,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_TAIWAN],
      useFactory: AngularFireStorageFactory,
    },
    {
      provide: FIREBASE_REFERENCES.US_FIREAUTH,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_US],
      useFactory: AngularFireAuthFactory,
    },
    {
      provide: FIREBASE_REFERENCES.TAIWAN_FIREAUTH,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_TAIWAN],
      useFactory: AngularFireAuthFactory,
    },
    {
      provide: FIREBASE_REFERENCES.US_FIREFUNCTIONS,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_US],
      useFactory: AngularFireFunctionshFactory,
    },
    {
      provide: FIREBASE_REFERENCES.TAIWAN_FIREFUNCTIONS,
      deps: [PLATFORM_ID, NgZone, ENV_CONFIG, FIREBASE_PROJECT_TAIWAN],
      useFactory: AngularFireFunctionshFactory,
    },
  ],
})
export class FirebaseModule {}
