<div class="pageWrapper">
  <div class="mainPage position-relative">
    <div class="header-box w-100 pt-2" >
      <div class="logo-box p-3 position-absolute">
        <img src="assets/images/shared/nn_logo.svg" class="img-fluid" alt="logo" [width]="40 * 1.5" [height]="29 * 1.5" >
      </div>
      <div class="icon-box pt-4 ps-5" >
        <img src="assets/images/shared/verify_phone.svg" class="img-fluid" alt="logo" width="80" height="80"  >
      </div>
      <div class="title-box pt-4 ps-5 text-start" >
        請進行簡訊驗證
      </div>
    </div>
    <div class="body-box" >
      <div class="title-box mx-auto my-3 w-100 px-4 py-2" >
        已寄送驗證碼至您的手機，若您的手機號碼錯誤，請聯繫諾和諾德後台人員協助修改。
      </div>
      <div class="mx-auto px-4 py-1" >
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>驗證碼</mat-label>
          <input type="text" matInput [(ngModel)]="verificationCode" autocomplete="one-time-code" inputmode="numeric" pattern="[0-9]*" maxlength="12"
                 placeholder="驗證碼">
        </mat-form-field>
      </div>

      <div class="action-box mx-auto px-4 py-2 text-center" >
        <button type="button" [disabled]="timer > 0" (click)="sendPhoneCode()"  >重寄驗證碼</button>
      </div>

      <div class="info-box mx-auto px-4 pt-2 pb-5 text-center" >
        若您未收到驗證碼 <br>
        <span *ngIf="this.timer > 0" > 待 {{this.timer}} 秒後 </span>可再點選按鈕重新獲得驗證碼
      </div>
      <div class="error-message-box mx-auto px-4 py-2 text-center" *ngIf="!!errorMessage" >
        {{errorMessage}}
      </div>
      <div class="mx-auto px-4 py-2" >
        <button mat-flat-button class="_btn-save mx-auto w-100" [disabled]="(!checkForm() || isProgress) && !checkVerificationCodeIsPhone()" (click)="_btnOtpVerify()">送出</button>
      </div>
      <div class="mx-auto px-4 py-2" >
        <button mat-flat-button class="_btn-prev mx-auto w-100" (click)="_btnPrev()">上一步</button>
      </div>
    </div>
  </div>
</div>
<div id="sign-in-button"></div>
