import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from './shared-material.module';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { CoreModule } from './methods/core.module';
import { CommonDialogComponent } from './components/common-dialog/common-dialog.component';
import { VerifyPhoneComponent } from './components/verify-phone/verify-phone.component';
import { InviteHcpQrcodeComponent } from './components/invite-hcp-qrcode/invite-hcp-qrcode.component';
import { HcpRegistrationFormComponent } from './components/hcp-registration-form/hcp-registration-form.component';

@NgModule({
  imports: [CommonModule, SharedMaterialModule, FormsModule, ReactiveFormsModule, CoreModule],
  declarations: [CommonDialogComponent, VerifyPhoneComponent, InviteHcpQrcodeComponent, HcpRegistrationFormComponent],
  exports: [ReactiveFormsModule, VerifyPhoneComponent, InviteHcpQrcodeComponent, HcpRegistrationFormComponent],
  providers: [FormBuilder],
  entryComponents: [],
})
export class SharedModule {}
