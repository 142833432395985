import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public progress = 0;

  public updateRichMenuOnCall = firebase.functions().httpsCallable('updateRichMenuOnCall');

  constructor(public storage: AngularFireStorage, public http: HttpClient, private router: Router) {}

  public async sleep(microsecond: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, microsecond);
    });
  }

  /*
  上傳檔案至firebase storage

  file(必要):檔案
  folder(必要):storage的目錄
  filename(可選):上傳後的檔案名稱
   */
  public uploadFile(
    file: File,
    folder: string,
    filename?: string
  ): Promise<{
    downloadURL: string;
    metadata: {
      size: number;
      updated: string;
      contentType: string;
      bucket: string;
      type: string;
      fullPath: string;
    };
  }> {
    return new Promise((res, rej) => {
      const fileNmae = filename ? filename : Date.now().toString() + '-' + file.name;
      const task = this.storage.upload(`/${folder}/${fileNmae}`, file);
      const fileRef = this.storage.ref(`/${folder}/${fileNmae}`);
      task.percentageChanges().subscribe((snapshot) => {
        this.progress = parseFloat(snapshot!.toFixed(2));
      });

      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe((downloadURL) => {
              fileRef.getMetadata().subscribe((Metadata) => {
                res({
                  downloadURL,
                  metadata: Metadata,
                });
              });
            });
          })
        )
        .subscribe();
    });
  }

  /*
   * 移除storage中的檔案
   * @param path 檔案路徑
   */
  public removeFile(path: string) {
    return Promise.resolve(this.storage.ref(path).delete());
  }

  //  * 修改指定 user 的 RichMenu
  //  * @param adminToken firebase 管理者 Token
  //  * @param site 站台名稱
  //  * @param userId userId
  //  * @param richMenuId  richMenuId 若為空值則重置 richMenu

  async updateRichMenu(adminToken: string, site: string, userId: string, richMenuId: string = '') {
    try {
      const result = await this.updateRichMenuOnCall({ idToken: adminToken, site, userId, richMenuId });
      return result.data;
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  ajaxFormPost<T = any>(url: string, data: any, apikey?: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    });

    if (apikey) {
      headers = headers.append('x-api-key', apikey);
    }

    let urlSearchParams = new URLSearchParams();
    for (let name in data) {
      urlSearchParams.set(name, data[name]);
    }
    return this.http.post<T>(url, urlSearchParams.toString(), { observe: 'body', headers: headers });
  }

  /**
   * ajax方法
   * @param url
   * @param data
   */
  ajaxJsonPost<T = any>(url: string, data: any, apikey?: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    });

    if (apikey) {
      headers = headers.append('x-api-key', apikey);
    }

    return this.http.post<T>(url, data, { observe: 'response', headers: headers });
  }

  /**
   * ajax方法
   * @param url
   * @param data
   */
  async Get<T = any>(url: string, data?: any) {
    const requestUrl = new URL(url);
    for (let name in data) {
      requestUrl.searchParams.set(name, data[name]);
    }
    const result = this.http.get<T>(requestUrl.href);
    return result;
  }

  /**
   * 頁面導航
   * @param path 路由
   * @param queryParams 查詢參數
   * @memberof SalesService
   * @example
   * navigate('/hcp/hcp-register', { site: 'nnhcp' })
   */
  navigate(path: string = '', queryParams: Object = { site: 'nnhcp' }): void {
    this.router.navigate([`${path}`], {
      queryParams: queryParams,
    });
  }
}
